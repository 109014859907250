.previewPage {
  display: flex;

  width: 100vw;
  height: 100vh;

  background-image: url("./assets/background3.png");
  background-repeat: no-repeat;
  background-size: cover;

  box-sizing: border-box;

  overflow-y: auto;

  position: relative;

  @media (max-width: 1920px) {
    background-image: url("./assets/background.png");
  }

  @media (max-width: 1840px) {
    background-image: url("./assets/background2.png");
    justify-content: center;
  }

  @media (max-width: 1250px) {
    background-image: url("./assets/background4.png");
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    font-family: Montserrat;
    font-size: 64px;
    font-weight: 400;

    color: white;

    z-index: 1;

    padding: 100px 0 40px 130px;

    @media (max-width: 1920px) {
      padding: 50px 0 40px 130px;
    }

    @media (max-width: 1250px) {
      padding-top: 50px;
    }

    @media (max-width: 1840px) {
      padding-left: 0;
    }

    .infoTop {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      @media (max-width: 1840px) {
        font-size: 39px;
      }

      @media (max-width: 1250px) {
        gap: 10px;
      }

      @media (max-width: 1250px) {
        font-size: 39px;
      }

      h1 {
        font-size: 94px;
        font-family: "Noto Sans", sans-serif;

        @media (max-width: 1840px) {
          font-size: 72px;
        }

        @media (max-width: 1250px) {
          font-size: 72px;
        }
      }

      .logo {
        @media (max-width: 1250px) {
          display: none;
        }
      }

      .smallLogo {
        display: none;
        @media (max-width: 1250px) {
          display: block;
        }
      }
    }

    .bottomInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      font-size: 43px;

      span {
        @media (max-width: 1250px) {
          display: none;
        }
      }

      .mobileInfo {
        display: flex;
        align-items: center;
        gap: 22px;

        @media (max-width: 1250px) {
          flex-direction: column-reverse;
          gap: 10px;
        }

        .buttons {
          display: flex;
          flex-direction: column;

          a {
            cursor: pointer;
          }
        }
      }
    }
  }
}
