.timer {
  font-family: "Roboto Mono", monospace;
  font-size: 64px;
  font-weight: 300;
  color: white;

  @media (max-width: 1160px) {
    font-size: 39px;
  }
}

