.elipse {
  width: 100%; 
  height: 100%;
  background: url("./assets/ellipse.png") no-repeat;

  @media (min-width: 1280px) and (min-height: 1024px) {
    background: url("./assets/ellipse.png") no-repeat;
  }

  @media (min-width: 1920px) and (min-height: 1080px) {
    background: url("./assets/ellipse.png") no-repeat;
  }

  @media (min-width: 2560px) and (min-height: 1440px) {
    background: url("./assets/ellipse.png") no-repeat;
  }
}

